<template>
    <div class="emptyState">
        <div class="emptyState__header">
            Wpisz szukaną frazę w pole.
        </div>
        <div class="emptyState__subtext">
            A następnie naciśnij "Wyszukaj".
        </div>
        <div class="emptyState__image">
            <img src="../assets/szukaj-min.png" alt="Najtansze komputery poleasingowe ze sklepu komputerowego. Znajdziesz równiez laptopy uzywane oraz tanie monitory full hd z matryca ips. W naszym asortymencie posiadamy szeroki wybor czesci komputerowych."> 
        </div>
    </div>
</template>
<script>
    export default {
        data() {
            return {
                
            }
        }
    }
</script>
<style lang="scss" scoped>
    // Color Variables Import
    @import '../styles/variables';

    .emptyState
    {
        width: 100vw;
        height: 45vh;
        display: flex;
        text-align: center;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        user-select: none;

        &__header
        {
            font-size: 1.25rem;
            font-weight: 500;
            opacity: 0.5;
        }

        &__subtext
        {
            font-size: 1rem;
            opacity: 0.5;
        }

        &__image
        {
            margin-top: 1.25em;
            opacity: 0.1;

            img
            {
                width: 80%;
                height: auto;
            }
        }
    }


</style>